.number-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 20rem;
  margin-bottom: 2rem;
}

.number-input label {
  margin-bottom: .5rem;
  font-size: 1.2rem;
  font-weight: 600;
  opacity: .5;
}

.number-input input {
  width: 100%;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 450;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
