.checklist {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 2rem;
}

.checklist-title {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  opacity: 0.5;
}

.checklist-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checklist-checkbox input {
  width: 1.3rem;
  height: 1.3rem;
}

.checklist-checkbox label {
  padding-left: 1rem;
  font-size: 1.1rem;
  font-weight: 450;
}
