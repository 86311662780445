.text-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 20rem;
  margin-bottom: 2rem;
  z-index: 1;
}

.text-input label {
  margin-bottom: .5rem;
  font-size: 1.2rem;
  font-weight: 600;
  opacity: .5;
}

.text-input input {
  width: 100%;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 450;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
}
