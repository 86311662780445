h1 {
  padding: 0 1rem;
  font-family: "Scripticus";
  font-size: 3rem;
  text-transform: uppercase;
}

h2 {
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 500;
}

h3 {
  font-size: 1.2rem;
  font-weight: 650;
}
