.navigation {
  grid-column: 1/2;
  grid-row: 1/3;
  padding: 1rem;
}

.navigation-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}
