.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  max-width: 100%;
  height: 100%;
  background-color: #fff;
  transition: width 0.5s, padding 0.5s, visibility 0.5s;
  overflow: auto;
}

.modal-open {
  min-width: 60rem;
}

.modal-close {
  width: 0;
}
