.stock-clothes {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 2rem;
}

.stock-clothes-photo {
  width: 30%;
  margin-right: 1rem;
  background-color: #FD5A46;
}