.button {
  margin-top: 2rem;
  padding: 1rem;
  border: 0;
  border-radius: 0.5rem;
  cursor: pointer;
}

.primary {
  color: #fff;
  background-color: #fd5a46;
}

.secondary {
  color: #fd5a46;
  border: 1px solid #fd5a46;
  background: none;
}
