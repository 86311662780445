.text,
.info-text,
.info-text-gray {
  font-size: 1rem;
  font-weight: 400;
}

.sub-title {
  margin: 1rem 0;
  font-size: 1.5rem;
}

.info-text,
.info-text-gray {
  margin: 1rem 0;
}

.info-text-gray {
  opacity: 0.5;
}
