.photo-loader {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
}

.photo-loader-item {
  height: 20rem;
  background-color: #fd5a46;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
