@import url("./assets/fonts/Scripticus/stylesheet.css");
@import url("./assets/fonts/Lora/stylesheet.css");
@import url("./assets/fonts/Montserrat/stylesheet.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Montserrat";
  font-size: clamp(10px, 0.83vw, 18px);
}
